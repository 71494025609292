export const OPEN_GIFT_DIALOG = "OPEN_GIFT_DIALOG";
export const CLOSE_GIFT_DIALOG = "CLOSE_GIFT_DIALOG";

export const GET_GIFT = "GET_GIFT";
export const CREATE_NEW_GIFT = "CREATE_NEW_GIFT";
export const EDIT_GIFT = "EDIT_GIFT";
export const DELETE_GIFT = "DELETE_GIFT";
export const DELETE_ALL_GIFT = "DELETE_ALL_GIFT";

export const SET_CREATE_GIFT_DONE = "SET_CREATE_GIFT_DONE";
export const UNSET_CREATE_GIFT_DONE = "UNSET_CREATE_GIFT_DONE";

export const SET_UPDATE_GIFT_DONE = "SET_UPDATE_GIFT_DONE";
export const UNSET_UPDATE_GIFT_DONE = "UNSET_UPDATE_GIFT_DONE";
