export const ALL_FAKE_HOST = "ALL_FAKE_HOST";
export const ADD_FAKEHOST = "ADD_FAKEHOST";
export const UPDATE_FAKEHOST = "UPDATE_FAKEHOST";
export const CLOSE_FAKE_HOST_DIALOG = "CLOSE_FAKE_HOST_DIALOG";
export const OPEN_FAKE_HOST_DIALOG = "OPEN_FAKE_HOST_DIALOG";
export const CREATE_NEW_FAKE_HOST = "CREATE_NEW_FAKE_HOST"
export const SET_CREATE_AGENCY_DONE = "SET_CREATE_AGENCY_DONE";
export const UNSET_CREATE_AGENCY_DONE = "UNSET_CREATE_AGENCY_DONE";
export const SET_UPDATE_AGENCY_DONE = "SET_UPDATE_AGENCY_DONE";
export const UNSET_UPDATE_AGENCY_DONE = "UNSET_UPDATE_AGENCY_DONE";
export const EDIT_FAKE_HOST = "EDIT_FAKE_HOST";
export const IS_ONLINE_FAKE_HOST = "IS_ONLINE_FAKE_HOST"