export const OPEN_COUNTRY_DIALOG = "OPEN_COUNTRY_DIALOG";
export const CLOSE_COUNTRY_DIALOG = "CLOSE_COUNTRY_DIALOG";

export const GET_COUNTRY = "GET_COUNTRY";
export const CREATE_NEW_COUNTRY = "CREATE_NEW_COUNTRY";
export const EDIT_COUNTRY = "EDIT_COUNTRY";
export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const DELETE_ALL_COUNTRY = "DELETE_ALL_COUNTRY";

export const SET_CREATE_COUNTRY_DONE = "SET_CREATE_COUNTRY_DONE";
export const UNSET_CREATE_COUNTRY_DONE = "UNSET_CREATE_COUNTRY_DONE";

export const SET_UPDATE_COUNTRY_DONE = "SET_UPDATE_COUNTRY_DONE";
export const UNSET_UPDATE_COUNTRY_DONE = "UNSET_UPDATE_COUNTRY_DONE";
