export const OPEN_IMAGE_DIALOG = "OPEN_IMAGE_DIALOG";
export const CLOSE_IMAGE_DIALOG = "CLOSE_IMAGE_DIALOG";

export const GET_IMAGE = "GET_IMAGE";
export const CREATE_NEW_IMAGE = "CREATE_NEW_IMAGE";
export const EDIT_IMAGE = "EDIT_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";

export const SET_CREATE_IMAGE_DONE = "SET_CREATE_IMAGE_DONE";
export const UNSET_CREATE_IMAGE_DONE = "UNSET_CREATE_IMAGE_DONE";

export const SET_UPDATE_IMAGE_DONE = "SET_UPDATE_IMAGE_DONE";
export const UNSET_UPDATE_IMAGE_DONE = "UNSET_UPDATE_IMAGE_DONE";
