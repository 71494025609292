export const GET_LEVEL = "GET_LEVEL";

export const CREATE_NEW_LEVEL = "CREATE_NEW_LEVEL";
export const SET_CREATE_LEVEL_DONE = "SET_CREATE_LEVEL_DONE";
export const UNSET_CREATE_LEVEL_DONE = "UNSET_CREATE_LEVEL_DONE";

export const EDIT_LEVEL = "EDIT_LEVEL";
export const SET_EDIT_LEVEL_DONE = "SET_EDIT_LEVEL_DONE";
export const UNSET_EDIT_LEVEL_DONE = "UNSET_EDIT_LEVEL_DONE";

export const DELETE_LEVEL = "DELETE_LEVEL";

export const OPEN_LEVEL_DIALOG = "OPEN_LEVEL_DIALOG";
export const CLOSE_LEVEL_DIALOG = "CLOSE_LEVEL_DIALOG";
