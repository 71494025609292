export const OPEN_STICKER_DIALOG = "OPEN_STICKER_DIALOG";
export const CLOSE_STICKER_DIALOG = "CLOSE_STICKER_DIALOG";

export const GET_STICKER = "GET_STICKER";
export const CREATE_NEW_STICKER = "CREATE_NEW_STICKER";
export const EDIT_STICKER = "EDIT_STICKER";
export const DELETE_STICKER = "DELETE_STICKER";
export const DELETE_ALL_STICKER = "DELETE_ALL_STICKER";

export const SET_CREATE_STICKER_DONE = "SET_CREATE_STICKER_DONE";
export const UNSET_CREATE_STICKER_DONE = "UNSET_CREATE_STICKER_DONE";

export const SET_UPDATE_STICKER_DONE = "SET_UPDATE_STICKER_DONE";
export const UNSET_UPDATE_STICKER_DONE = "UNSET_UPDATE_STICKER_DONE";
