export const SET_ADMIN = "SET_ADMIN";
export const UNSET_ADMIN = "UNSET_ADMIN";

export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";

export const UPDATE_PROFILE = "UPDATE_PROFILE";


export const SIGNUP_ADMIN = "SIGNUP_ADMIN";
export const SET_REGISTER_ERROR = "SET_REGISTER_ERROR";
export const CLEAR_REGISTER_ERROR = "CLEAR_REGISTER_ERROR";
export const SET_UPDATE_CODE_ERROR = "SET_UPDATE_CODE_ERROR";
export const CLEAR_UPDATE_CODE_ERROR = "CLEAR_UPDATE_CODE_ERROR";
export const GET_PROFILE = "GET_PROFILE";