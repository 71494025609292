export const OPEN_BANNER_DIALOG = "OPEN_BANNER_DIALOG";
export const CLOSE_BANNER_DIALOG = "CLOSE_BANNER_DIALOG";

export const GET_BANNER = "GET_BANNER";
export const CREATE_NEW_BANNER = "CREATE_NEW_BANNER";
export const EDIT_BANNER = "EDIT_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";

export const SET_CREATE_BANNER_DONE = "SET_CREATE_BANNER_DONE";
export const UNSET_CREATE_BANNER_DONE = "UNSET_CREATE_BANNER_DONE";

export const SET_UPDATE_BANNER_DONE = "SET_UPDATE_BANNER_DONE";
export const UNSET_UPDATE_BANNER_DONE = "UNSET_UPDATE_BANNER_DONE";
